<template>
  <div class="text-center mb-3">
    <h1 class="error-title text-verde">403</h1>
    <h5 class="text-wrap">{{$t('general.errors.forbidden.message')}}</h5>
    <p v-if="errorCode" class="text-wrap text-danger font-weight-bold">{{$t('general.errors.error_code', {error_code: errorCode})}}</p>
    <br class="clearfix" />
<!--    <button v-if="hasHistory" class="font-size-lg" @click="$router.go(-1)">{{ $t('general.errors.navigation') }}</button>-->
    <button class="verde-link" @click="goToDashboard">{{ $t('general.errors.navigation_dashboard') }}</button>
    <br />
    <br />
    <button class="verde-link" @click="relogin">{{ $t('general.errors.navigation_login') }}</button>
  </div>
</template>

<script>
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import {AuthorizationMixin} from "@/mixins/GeneralMixin";

export default {
  name: "ErrorForbidden",
  mixins: [LoadingModalMixin, AuthorizationMixin],
  computed: {
    hasHistory () { return window.history.length > 2 },
    errorCode() { return this.$route.query.code ?? "" }
  },
  methods: {
    relogin: function () {
      let that = this;
      this.queueJob();
      this.logoutToken().finally(() => {
        that.completeJob();
        location.href = '/';
      });
    },
    goToDashboard: function () {
      if (this.isLoggedInUserAndValid) {
        this.$router.push({name: 'general.breadcrumb.dashboard'})
        return;
      }
      this.relogin();
    }
  }
}
</script>

<style scoped>
</style>